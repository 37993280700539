.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale(1.1)rotate(-3deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    transform: translateX(20%)rotate(3deg);
  }

  45% {
    transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    transform: translateX(10%)rotate(2deg);
  }

  75% {
    transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

.animated.flip {
  backface-visibility: visible;
  -ms-backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  100% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(0);
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(80deg);
  }

  40% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(700px);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)rotate(120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}
/*# sourceMappingURL=index.a0d58726.css.map */
